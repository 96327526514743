import React, { useEffect, useState } from "react";
import { isValidUrl } from "../../utils/stringChecks";
import "./SSOButton.scss";
import { clientProps, getWhitelabelClient } from "../../utils/whitelabel";

const SSOButton = (props) => {
  const [sourceRedirectClient, setSourceRedirectClient] = useState(null);
  const [whitelabelTheme, setWhiteLabelTheme] = useState(null);

  useEffect(() => {
    let urlParams = new URLSearchParams(window.location.search);
    let sourceUrl = urlParams.get("continue");

    if (isValidUrl(sourceUrl)) {
      const client = getWhitelabelClient(sourceUrl);
      setWhiteLabelTheme(clientProps[client]);
      setSourceRedirectClient(client);
    } else {
      setSourceRedirectClient("intellicar");
    }
  }, []);

  return (
    <button
      style={
        sourceRedirectClient !== "intellicar"
          ? {
              backgroundImage: "none",
              backgroundColor: whitelabelTheme?.clientPrimaryColor || "#000000",
            }
          : {}
      }
      className="ssoBtn"
      onClick={() => props.callbackFunc()}
      disabled={props.isDisabled || false}
    >
      {props.text}
    </button>
  );
};

export default SSOButton;

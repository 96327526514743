import React from 'react';
import './Dashboard.scss';

import {products} from '../../utils/products';

import Header from '../../Components/Header';

const Dashboard = () => {

  let openUrl = (url) => {
    window.open(url, '_blank');

  }
  
  let renderProducts = (type) => {
    return products[type].map((prod) => {
      return(
        <div className="productCard" key={prod.title}>
          <div className="cardBody">

            <div className="left">
              <div className="productTitle">{prod.title}</div>
              <div className="productDescription">{prod.description}</div>
            </div>
            <div className="right">
              <img src={"/images/products/"+prod.image} alt={prod.title} />
            </div>
          </div>
          <div className="cardFooter">
            <button onClick={() => {openUrl(prod.url)}}>{type === 'web' ? "Visit" : "Download"}</button>
          </div>
          
        </div>
      )
    })
  }

  return(
    <div className="DashboardScreen">
      <Header />
      <div className="MainScreen">
        <div className="title">Web Apps</div>
        <div className="productContainer">
          {renderProducts("web")}
        </div>
        <div className="title">Mobile Apps</div>
        <div className="productContainer">
          {renderProducts("mobile")}
        </div>
      </div>
    </div>
  )
}

export default Dashboard
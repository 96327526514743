import React, { useState, useEffect } from "react";
import "./Identifier.scss";
import { isValidUrl, isValidEmailAddress } from "../../../utils/stringChecks";
import { urls } from "../../../utils/constant.js";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import SSOButton from "../../../Components/SSOButton";
import SSOInput from "../../../Components/SSOInput";
import Loader from "../../../Components/Loader";
import { getWhitelabelClient } from "../../../utils/whitelabel";

const Identifier = () => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [sourceRedirectClient, setSourceRedirectClient] = useState(null);

  useEffect(() => {
    let urlParams = new URLSearchParams(window.location.search);
    let sourceUrl = urlParams.get("continue");

    if (isValidUrl(sourceUrl)) {
      const client = getWhitelabelClient(sourceUrl);
      setSourceRedirectClient(client);
    } else {
      setSourceRedirectClient("intellicar");
    }
  }, []);

  let renderSourceUrl = () => {
    let urlParams = new URLSearchParams(window.location.search);
    let sourceUrl = urlParams.get("continue");

    if (sourceUrl === "no_redirect") {
      return null;
    } else {
      if (isValidUrl(sourceUrl)) {
        return sourceUrl.split("//")[1];
      } else {
        return null;
      }
    }
  };

  let apiCallToCheckUserAndFetchSSOOptions = (useremail = null) => {
    setIsLoading(true);
    let urlParams = new URLSearchParams(window.location.search);
    let sourceUrl = urlParams.get("continue");

    let api_url = urls.apiUrl;
    if (sourceUrl && sourceUrl.includes("staging")) {
      api_url = urls.stagingApiUrl;
    }
    let userEmail = useremail ? useremail : email;
    if (isValidEmailAddress(userEmail)) {
      let body = {
        username: userEmail,
        referer: sourceUrl,
      };
      // after email is authenticated and options received
      axios
        .post(api_url + "/sso/getlogininfo", body)
        .then((response) => {
          if (response.data.data.logintype === "invalid") {
            setIsLoading(false);
            if (response.data.status === "SUCCESS") {
              toast.success(response.data.msg);
            } else {
              toast.error("Oops!! You don't have login access");
            }
          } else {
            let loginTypeArr = [];
            loginTypeArr.push(response.data.data.logintype);
            localStorage.setItem("identifiedEmail", userEmail);
            localStorage.setItem("userOptions", JSON.stringify(loginTypeArr));
            window.location.href = "/signin/challenge?" + urlParams.toString();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setIsLoading(false);
      toast.error("Invalid Email Id");
      return false;
    }
  };

  let renderToContinueText = () => {
    let urlParams = new URLSearchParams(window.location.search);
    let sourceUrl = urlParams.get("continue");

    if (sourceUrl) {
      return (
        <div className="sourceUrlName">to continue to {renderSourceUrl()}</div>
      );
    }
  };

  let selectIdentifier = (email) => {
    apiCallToCheckUserAndFetchSSOOptions(email);
  };

  let renderSignedInEmails = () => {
    let signedInEmails =
      JSON.parse(localStorage.getItem("signedInEmails")) || [];
    return signedInEmails.map((email) => {
      return (
        <li
          key={email}
          onClick={() => {
            selectIdentifier(email);
          }}
        >
          <i className="fas fa-user-circle"></i>
          <span>{email}</span>
        </li>
      );
    });
  };

  let keyPress = (e) => {
    if (e.keyCode === 13 && email) {
      apiCallToCheckUserAndFetchSSOOptions();
    }
  };

  return (
    <div
      className="IdentifierScreen"
      onKeyDown={(e) => {
        keyPress(e);
      }}
    >
      <div className="loginBox">
        <Loader isLoading={isLoading} />
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="logo">
          <img
            src={`/images/${sourceRedirectClient}.png`}
            alt={`${sourceRedirectClient} Logo`}
          />
        </div>
        {/* <div
          className="helperButton"
          onClick={() =>
            window.open(
              "https://drive.google.com/file/d/1U3vSv_kbJVG4duavi5G0rdyG7pWHBLkA/view?usp=sharing",
              "_blank"
            )
          }
        >
          <span>Need Help</span>
          <img src={QuestionIcon} alt="help" />
        </div> */}
        <div className="header">Sign in</div>
        {renderToContinueText()}
        <ul className="renderSignedInEmailsContainer">
          {renderSignedInEmails()}
        </ul>
        <div className="formControl">
          <SSOInput
            inputType="email"
            placeholder="Email / Username"
            callbackFunc={setEmail}
            inputValue={email}
          />
        </div>
        <div className="btnContainer">
          <SSOButton
            text="Next"
            callbackFunc={apiCallToCheckUserAndFetchSSOOptions}
            isDisabled={email ? false : true}
          />
        </div>
        <div className="btnLabel">
          <span>press ENTER</span>
        </div>
      </div>
    </div>
  );
};

export default Identifier;

import { isValidUrl, isValidEmailAddress } from "./stringChecks";
import { clientProps, getWhitelabelClient } from "./whitelabel";
const setRootUserCookie = (impPerm, token, userInfo, skipImp = "false") => {
  resetRootCookie();
  resetTemporaryImpCookie();
  let obj = {
    impPerm: impPerm,
    skipImp: skipImp,
    token: token,
    userInfo: userInfo,
  };
  let urlParams = new URLSearchParams(window.location.search);
  let sourceUrl = urlParams.get("continue") || window.location.href;
  const client = getWhitelabelClient(sourceUrl);
  window.document.cookie = clientProps[client].getCookieString(
    "add",
    JSON.stringify(obj),
    "rootStore"
  );
};

const setImpUserCookie = (impToken, impUserInfo) => {
  resetTemporaryImpCookie();
  let application_url = getSourceApplicationUrl();
  let obj = {
    token: impToken,
    userInfo: impUserInfo,
  };
  if (application_url) {
    let urlParams = new URLSearchParams(window.location.search);
    let sourceUrl = urlParams.get("continue") || window.location.href;
    const client = getWhitelabelClient(sourceUrl);
    window.document.cookie = clientProps[client].getCookieString(
      "add",
      JSON.stringify(obj),
      "temporaryImpStore"
    );
  }
};

const resetRootCookie = () => {
  let urlParams = new URLSearchParams(window.location.search);
  let sourceUrl = urlParams.get("continue") || window.location.href;
  const client = getWhitelabelClient(sourceUrl);
  window.document.cookie = clientProps[client].getCookieString(
    "delete",
    null,
    "rootStore"
  );
};

const resetTemporaryImpCookie = () => {
  let urlParams = new URLSearchParams(window.location.search);
  let sourceUrl = urlParams.get("continue") || window.location.href;
  const client = getWhitelabelClient(sourceUrl);
  window.document.cookie = clientProps[client].getCookieString(
    "delete",
    null,
    "temporaryImpStore"
  );
};

const getRootUserDetails = () => {
  let allcookies = document.cookie;
  let cookiearray = allcookies.split(";");
  let name;
  let value;
  for (var i = 0; i < cookiearray.length; i++) {
    name = cookiearray[i].split("=")[0];

    if (name.trim() === "rootStore") {
      value = cookiearray[i].split("=")[1];
      break;
    } else {
      value = JSON.stringify({});
    }
  }
  return value;
};

const getSourceApplicationUrl = () => {
  let urlParams = new URLSearchParams(window.location.search);
  let sourceUrl = urlParams.get("continue");

  if (sourceUrl === "no_redirect") {
    return null;
  } else {
    if (isValidUrl(sourceUrl)) {
      return sourceUrl.split("//")[1];
    } else {
      return null;
    }
  }
};

export {
  resetRootCookie,
  resetTemporaryImpCookie,
  setRootUserCookie,
  setImpUserCookie,
  getRootUserDetails,
};

// signedInEmails: "['sunny.sharma@intellicar.in','sunnynk19@gmail.com']",
// signedInImpUsers: "['fleet.admin','zoom.blr','smrt.ui']",

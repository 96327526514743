import React from 'react';
import {
  Route,
} from "react-router-dom";

import CheckAuth from './CheckAuth';

const PrivateRoute = ({ children, ...rest }) => {
  let urlParams = new URLSearchParams(window.location.search);

  return(
    <Route
      {...rest}
      render={({ location }) =>
      CheckAuth() ? (
          children
        ) : (
          window.location.href = "/signin/identifier?"+urlParams.toString()
        )
      }
    />
  )
}
  
export default PrivateRoute
export const getWhitelabelClient = (hostname) => {
  try {
    if (hostname.match("buzzenergy")) {
      return "buzzenergy";
    } else if (hostname.match("viryamobility")) {
      return "viryamobility";
    } else if (hostname.match("perpetuitycapital")) {
      return "perpetuitycapital";
    } else if (hostname.match("greendrivemobility")) {
      return "greendrivemobility";
    } else if (hostname.match("bilitielectric")) {
      return "bilitielectric";
    } else if (hostname.match("mondays")) {
      return "mondays";
    } else if (hostname.match("etomotors")) {
      return "etomotors";
    } else if (hostname.match("intellicar.io")) {
      return "intellicario";
    } else if (hostname.match("metafin")) {
      return "metafin";
    } else if (hostname.match("gofuel")) {
      return "gofuel";
    } else {
      return "intellicar";
    }
  } catch (error) {
    return "intellicar";
  }
};

const getCookieString = (
  domain,
  action,
  obj = null,
  storeType = "authStore"
) => {
  if (action === "delete") {
    return ` ${storeType}=; path=/; domain=${domain}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  }

  return ` ${storeType}=${obj}; path=/; domain=${domain};`;
};

export const clientProps = {
  intellicar: {
    authUrl: "https://auth.intellicar.in",
    clientLogo: "https://auth.intellicar.in/images/intellicar.png",
    clientPrimaryColor: "#ab0b32",
    getCookieString: (action, obj = null, storeType = "authStore") =>
      getCookieString("intellicar.in", action, obj, storeType),
  },
  buzzenergy: {
    authUrl: "https://auth.buzzenergy.in",
    clientLogo: "https://auth.buzzenergy.in/images/buzzenergy.png",
    clientPrimaryColor: "#5BDBB6",
    getCookieString: (action, obj = null, storeType = "authStore") =>
      getCookieString("buzzenergy.in", action, obj, storeType),
  },
  viryamobility: {
    authUrl: "https://auth.viryamobility.com",
    clientLogo: "https://auth.viryamobility.com/images/viryamobility.png",
    clientPrimaryColor: "#e74c3c",
    getCookieString: (action, obj = null, storeType = "authStore") =>
      getCookieString("viryamobility.com", action, obj, storeType),
  },
  perpetuitycapital: {
    authUrl: "https://auth.intellicar.in",
    clientLogo: "https://auth.intellicar.in/images/perpetuitycapital.png",
    clientPrimaryColor: "#1d5632",
    getCookieString: (action, obj = null, storeType = "authStore") =>
      getCookieString("intellicar.in", action, obj, storeType),
  },
  bilitielectric: {
    authUrl: "https://auth.bilitielectric.in",
    clientLogo: "https://auth.bilitielectric.in/images/bilitielectric.png",
    clientPrimaryColor: "#48E875",
    getCookieString: (action, obj = null, storeType = "authStore") =>
      getCookieString("bilitielectric.in", action, obj, storeType),
  },
  mondays: {
    authUrl: "https://auth.mondays.in",
    clientLogo: "https://auth.mondays.in/images/mondays.png",
    clientPrimaryColor: "#ab0b32",
    getCookieString: (action, obj = null, storeType = "authStore") =>
      getCookieString("mondays.in", action, obj, storeType),
  },
  etomotors: {
    authUrl: "https://auth.etomotors.com",
    clientLogo: "https://auth.etomotors.com/images/etomotors.png",
    clientPrimaryColor: "#8fcb18",
    getCookieString: (action, obj = null, storeType = "authStore") =>
      getCookieString("etomotors.com", action, obj, storeType),
  },
  intellicario: {
    authUrl: "https://auth.intellicar.io",
    clientLogo: "https://auth.intellicar.io/images/intellicar.png",
    clientPrimaryColor: "#ab0b32",
    getCookieString: (action, obj = null, storeType = "authStore") =>
      getCookieString("intellicar.io", action, obj, storeType),
  },
  metafin: {
    authUrl: "https://auth.metafin.in",
    clientLogo: "https://auth.metafin.in/images/metafin.png",
    clientPrimaryColor: "#8fcb18",
    getCookieString: (action, obj = null, storeType = "authStore") =>
      getCookieString("metafin.in", action, obj, storeType),
  },
  gofuel: {
    authUrl: "https://auth.gofuel.in",
    clientLogo: "https://auth.gofuel.in/images/gofuel.png",
    clientPrimaryColor: "#ab0b32",
    getCookieString: (action, obj = null, storeType = "authStore") =>
      getCookieString("gofuel.in", action, obj, storeType),
  }
};

import React from 'react';
import Identifier from './Screens/Login/Identifier';
import Challenge from './Screens/Login/Challenge';
import Impersonate from './Screens/Login/Impersonate';
import ForgotPassword from './Screens/Login/ForgotPassword';
import IFrame from './Screens/IFrame';
import Dashboard from './Screens/Dashboard';
import PrivateRoute from './Components/PrivateRoute';
import './App.scss';

import {isValidUrl} from './utils/stringChecks'; 

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

function App() {

  let checkTokenAndRedirectRoot = () => {
    let urlParams = new URLSearchParams(window.location.search);
    let sourceUrl = urlParams.get("continue");
    if(checkToken()){
      if(sourceUrl){
        if(sourceUrl === 'no_redirect'){
          return(<Redirect exact from="/" to="/dashboard"/>);
        }
        else{
          if(isValidUrl(sourceUrl)){
            redirectBackToRequestSrc();
          }
          else{
            return(<Redirect exact from="/" to="/dashboard"/>);
          }
        }
      }
      else{
        return(<Redirect exact from="/" to="/dashboard"/>);
      }
    }
    else{
      let impPerm = localStorage.getItem("impPerm");
      let impToken = localStorage.getItem("impToken");
      if(impPerm === "true" && !impToken){
        return(<Redirect exact from="/" to={"/signin/impersonate?"+urlParams.toString()} />)
      }
      return(<Redirect exact from="/" to={"/signin/identifier?"+urlParams.toString()} />)
    }
  }

  let checkToken = () => {
    let token = localStorage.getItem("token");
    if(token && token !==  undefined){
      let impPerm = localStorage.getItem("impPerm");
      if(impPerm === "true"){
        let impToken = localStorage.getItem("impToken");
        if(impToken && impToken !==  undefined){
          return true
        }
        else{
          return false
        }
      }
      else{
        return true
      }
    }
    else{
      return false
    }
  }

  let redirectBackToRequestSrc = () => {
    console.log("redirected to source");
  }

  return (
    <Router>
      <div className="App">
        {/* <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
        </ul> */}

        <Switch>
          <Route exact path="/signin/identifier">
            <Identifier />
          </Route>
          <Route exact path="/signin/challenge">
            <Challenge />
          </Route>
          <Route exact path="/signin/impersonate">
            <Impersonate />
          </Route>
          <Route exact path="/signin/reset-password">
            <ForgotPassword />
          </Route>
          <Route exact path="/IFrame">
            <IFrame />
          </Route>
          <PrivateRoute exact path="/dashboard">
            <Dashboard />
          </PrivateRoute>
          {checkTokenAndRedirectRoot()}
        </Switch>
      </div>
    </Router>
    
  );
}

export default App;

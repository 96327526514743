import React, { useState, useEffect } from "react";
import "./Impersonate.scss";

import SSOButton from "../../../Components/SSOButton";
import SSOInput from "../../../Components/SSOInput";
import Loader from "../../../Components/Loader";

import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { urls } from "../../../utils/constant.js";
import {
  getRootUserDetails,
  resetRootCookie,
  setImpUserCookie,
  setRootUserCookie,
} from "../../../utils/cookieMgmt.js";
import { isValidUrl } from "../../../utils/stringChecks";
import { getWhitelabelClient } from "../../../utils/whitelabel";

const Impersonate = () => {
  const [impersonateUserId, setImpersonateUserId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [skipImp, setSkipImp] = useState(false);
  const [sourceRedirectClient, setSourceRedirectClient] = useState(null);

  useEffect(() => {
    let urlParams = new URLSearchParams(window.location.search);
    let sourceUrl = urlParams.get("continue");

    if (isValidUrl(sourceUrl)) {
      const client = getWhitelabelClient(sourceUrl);
      setSourceRedirectClient(client);
    } else {
      setSourceRedirectClient("intellicar");
    }
  }, []);

  useEffect(() => {
    redirectToParticularPage();
  }, []);

  let redirectToParticularPage = async () => {
    let rootUserDetails = JSON.parse(getRootUserDetails());
    let token = rootUserDetails.token || null;
    let impPerm = rootUserDetails.impPerm || null;
    let urlParams = new URLSearchParams(window.location.search);
    let sourceUrl = urlParams.get("continue");
    if (token) {
      let isValidToken = await verifyToken(token);
      if (isValidToken) {
        if (impPerm === "false") {
          // setCookiesForNonImpUser();
          if (sourceUrl) {
            setIsLoading(true);
            setTimeout(function () {
              window.close();
            }, 2000);
          } else {
            window.location.href = "/dashboard";
          }
        }
      } else {
        resetRootCookie();
        localStorage.removeItem("identifiedEmail");
        localStorage.removeItem("userOptions");
        window.location.href = "/signin/identifier?" + urlParams.toString();
      }
    } else {
      resetRootCookie();
      localStorage.removeItem("identifiedEmail");
      localStorage.removeItem("userOptions");
      window.location.href = "/signin/identifier?" + urlParams.toString();
    }
  };

  let verifyToken = async (token) => {
    let urlParams = new URLSearchParams(window.location.search);
    let sourceUrl = urlParams.get("continue");

    let api_url = urls.apiUrl;
    if (sourceUrl && sourceUrl.includes("staging")) {
      api_url = urls.stagingApiUrl;
    }
    return await axios
      .post(api_url + "/sso/verifytoken", { token: token })
      .then((response) => {
        return response.data.data.valid;
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
  };

  let apiCallToImpersonateUser = (impUserID = null) => {
    setIsLoading(true);
    let rootUserDetails = JSON.parse(getRootUserDetails());
    let rootToken = rootUserDetails.token;
    let impUser = impUserID ? impUserID : impersonateUserId;

    let urlParams = new URLSearchParams(window.location.search);
    let sourceUrl = urlParams.get("continue");

    if (
      impUser ===
      (rootUserDetails.userInfo ? rootUserDetails.userInfo.username : "")
    ) {
      skipImpersonation();
    } else {
      let body = {
        impusername: impUser,
        token: rootToken,
        referer: sourceUrl,
      };

      let api_url = urls.apiUrl;

      if (sourceUrl && sourceUrl.includes("staging")) {
        api_url = urls.stagingApiUrl;
      }

      axios
        .post(api_url + "/sso/impersonate", body)
        .then((response) => {
          setIsLoading(false);
          let userdata = response.data.data;
          setImpUserCookie(userdata.token, JSON.stringify(userdata.userinfo));

          let signedInImpUsers =
            JSON.parse(localStorage.getItem("signedInImpUsers")) || [];
          if (!signedInImpUsers.includes(impUser)) {
            signedInImpUsers.unshift(impUser);
            localStorage.setItem(
              "signedInImpUsers",
              JSON.stringify(signedInImpUsers)
            );
          }

          cleanLocalStorage();
          if (sourceUrl) {
            setIsLoading(true);
            // console.log("cookie set 2");
            setTimeout(function () {
              window.close();
            }, 2000);
          } else {
            window.location.href = "/dashboard";
          }
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(err.response.data.msg);
          // console.log(err.response.data.msg);
          return false;
        });
    }
  };

  let skipImpersonation = () => {
    setSkipImp(true);
    let urlParams = new URLSearchParams(window.location.search);
    let sourceUrl = urlParams.get("continue");
    let rootUserDetails = JSON.parse(getRootUserDetails());
    setRootUserCookie(
      rootUserDetails.impPerm,
      rootUserDetails.token,
      rootUserDetails.userInfo,
      "true"
    );

    cleanLocalStorage();
    if (sourceUrl) {
      setIsLoading(true);
      setTimeout(function () {
        window.close();
      }, 2000);
    } else {
      window.location.href = "/dashboard";
    }
  };

  let cleanLocalStorage = () => {
    localStorage.removeItem("identifiedEmail");
    localStorage.removeItem("userOptions");
  };

  let renderSignInButton = () => {
    return (
      <>
        <div className="btnContainer">
          <SSOButton text="Skip" callbackFunc={skipImpersonation} />
          <SSOButton
            text="Impersonate"
            callbackFunc={apiCallToImpersonateUser}
            isDisabled={impersonateUserId ? false : true}
          />
        </div>
        <div className="btnLabel">
          <span>press ESC</span>
          <span>press ENTER</span>
        </div>
      </>
    );
  };

  let renderUserEmail = () => {
    let rootUserDetails = JSON.parse(getRootUserDetails());
    let userInfo = JSON.parse(rootUserDetails.userInfo);
    if (userInfo && userInfo.username) {
      return userInfo.username;
    }
  };

  let selectIdentifier = (email) => {
    apiCallToImpersonateUser(email);
  };

  let renderSignedInEmails = () => {
    let signedInImpUsers =
      JSON.parse(localStorage.getItem("signedInImpUsers")) || [];
    return signedInImpUsers.map((email) => {
      return (
        <li
          key={email}
          onClick={() => {
            selectIdentifier(email);
          }}
        >
          <i className="fas fa-address-card"></i>
          <span>{email}</span>
        </li>
      );
    });
  };

  let keyPress = (e) => {
    if (e.keyCode === 13 && impersonateUserId) {
      apiCallToImpersonateUser();
    }

    if (e.keyCode === 27) {
      skipImpersonation();
    }
  };

  let logout = () => {
    let urlParams = new URLSearchParams(window.location.search);
    resetRootCookie();
    localStorage.removeItem("identifiedEmail");
    localStorage.removeItem("userOptions");
    window.location.href = "/signin/identifier?" + urlParams.toString();
  };

  return (
    <div
      className="ImpersonateScreen"
      onKeyDown={(e) => {
        keyPress(e);
      }}
    >
      <div className="loginBox">
        <Loader isLoading={isLoading} />
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="logo">
          <img
            src={`/images/${sourceRedirectClient}.png`}
            alt={`${sourceRedirectClient} Logo`}
          />
        </div>
        <div className="header">You have signed in as</div>
        <div className="emailBox">
          <i className="fas fa-user-circle"></i>
          <span>{renderUserEmail()}</span>
        </div>

        <div className="logout-link">
          <span onClick={logout}>Logout</span>
        </div>

        <ul className="renderSignedInEmailsContainer">
          {renderSignedInEmails()}
        </ul>
        <div className="formControl">
          <SSOInput
            inputType="text"
            placeholder="Impersonate User"
            callbackFunc={setImpersonateUserId}
            inputValue={impersonateUserId}
          />
        </div>
        {renderSignInButton()}
      </div>
    </div>
  );
};

export default Impersonate;

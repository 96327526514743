import React, { useState, useEffect } from "react";
import "./Header.scss";
import { clientProps, getWhitelabelClient } from "../../utils/whitelabel";

const Header = (props) => {
  const [isDropDownActive, setIsDropDownActive] = useState(false);
  const [rootUser, setRootUser] = useState("");
  const [impUser, setImpuser] = useState("");

  useEffect(() => {
    let userInfo = localStorage.getItem("userInfo");
    if (userInfo) {
      setRootUser(JSON.parse(userInfo).username);
    }

    let impUserInfo = localStorage.getItem("impUserInfo");
    if (impUserInfo) {
      setImpuser(JSON.parse(impUserInfo).username);
    }
  }, []);

  let logout = () => {
    let urlParams = new URLSearchParams(window.location.search);
    let sourceUrl = urlParams.get("continue");
    const client = getWhitelabelClient(sourceUrl);
    localStorage.removeItem("identifiedEmail");
    localStorage.removeItem("impPerm");
    localStorage.removeItem("impToken");
    localStorage.removeItem("impUserInfo");
    localStorage.removeItem("token");
    localStorage.removeItem("userInfo");
    localStorage.removeItem("userOptions");
    localStorage.setItem("skipImp", false);
    window.document.cookie = clientProps[client].getCookieString("delete");
    window.location.href = "/signin/identifier";
  };

  let switchUser = () => {
    let urlParams = new URLSearchParams(window.location.search);
    let sourceUrl = urlParams.get("continue");
    const client = getWhitelabelClient(sourceUrl);
    localStorage.removeItem("impToken");
    localStorage.removeItem("impUserInfo");
    localStorage.setItem("skipImp", false);
    window.document.cookie = clientProps[client].getCookieString("delete");
    window.location.href = "/signin/impersonate";
  };

  let renderImpersonatedUser = () => {
    if (impUser) {
      return (
        <li>
          <div className="impersonatedAs">
            <div className="title">Impersonated As</div>
            <div className="sub-title">{impUser}</div>
            <button className="white-btn" onClick={() => switchUser()}>
              Switch User
            </button>
          </div>
        </li>
      );
    }
  };

  let renderDropDown = () => {
    if (isDropDownActive) {
      return (
        <>
          <div
            className="dropdown-backdrop"
            onClick={() => setIsDropDownActive(!isDropDownActive)}
          ></div>
          <ul className="dropdown">
            {renderImpersonatedUser()}
            <li>
              <div className="loggedInAs">
                <div className="title">Logged In As</div>
                <div className="sub-title">{rootUser}</div>
                <button className="red-btn" onClick={() => logout()}>
                  Sign Out
                </button>
              </div>
            </li>
          </ul>
        </>
      );
    }
  };

  return (
    <div className="Header">
      <div className="logo">
        <img src="/images/intellicar.png" alt="Intellicar" />
      </div>
      <div className="dropdownContainer">
        <button onClick={() => setIsDropDownActive(!isDropDownActive)}>
          <i className="fas fa-cog"></i>
          <span>Settings</span>
        </button>
        {renderDropDown()}
      </div>
    </div>
  );
};

export default Header;

import React, {useRef, useEffect} from 'react';
import './SSOInput.scss';

import {isValidEmailAddress} from '../../utils/stringChecks';

const supportedInput = ['text', 'email', 'password', 'number']

const SSOInput = (props) => {

  const inputBox = useRef();
  useEffect(() => {inputBox.current && inputBox.current.focus()}, []);

  
  let checkInputAndReturn = () => {
    if(supportedInput.includes(props.inputType)){
      return <input className={props.hasError ? 'error': ''} ref={inputBox} type={props.inputType} placeholder={props.placeholder} value={props.inputValue} onChange={(e) => OnInputChange(e.target.value)} />
    }
    return "This input is not supported. Try text, email, password or number"
  }

  let OnInputChange = (val) => {
    props.callbackFunc(val)

    if(props.inputType === 'email'){
      if(!(val && isValidEmailAddress(val))){
        inputBox.current.classList.add("error");
      }
      else{
        inputBox.current.classList.remove("error");
      }
    }

  }

  return(
    checkInputAndReturn()
  )
}

export default SSOInput;
import React, { useEffect } from "react";
import {
  getRootUserDetails,
  resetRootCookie,
  setRootUserCookie,
} from "../../utils/cookieMgmt.js";

const IFrame = () => {
  useEffect(() => {
    window.onmessage = function (event) {
      if (event && event.data && event.data !== "recaptcha-setup") {
        let action = JSON.parse(event.data).action;
        if (action === "switchUser") {
          let rootUserDetails = JSON.parse(getRootUserDetails());
          setRootUserCookie(
            rootUserDetails.impPerm,
            rootUserDetails.token,
            rootUserDetails.userInfo,
            "false"
          );
        } else if (action === "logout") {
          resetRootCookie();
        }
      }
    };
  }, []);

  return <div id="main"></div>;
};

export default IFrame;

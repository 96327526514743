import { clientProps, getWhitelabelClient } from "../utils/whitelabel";

const CheckAuth = () => {
  let rootToken = localStorage.getItem("token");
  let impPerm = localStorage.getItem("impPerm");
  let skipImp = localStorage.getItem("skipImp");
  let impToken = localStorage.getItem("impToken");
  let urlParams = new URLSearchParams(window.location.search);
  let sourceUrl = urlParams.get("continue");
  const client = getWhitelabelClient(sourceUrl);
  if (rootToken) {
    if (impPerm === "true" && skipImp === "false" && !impToken) {
      return false;
    } else {
      return true;
    }
  } else {
    localStorage.removeItem("identifiedEmail");
    localStorage.removeItem("impPerm");
    localStorage.removeItem("impToken");
    localStorage.removeItem("impUserInfo");
    localStorage.removeItem("token");
    localStorage.removeItem("userInfo");
    localStorage.removeItem("userOptions");
    window.document.cookie = clientProps[client].getCookieString("delete");
    return false;
  }
};

export default CheckAuth;

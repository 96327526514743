export const products = {
    "web": [
        {
            "title": "Track",
            "description": "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old",
            "image": "track.svg",
            "url": "https://track.intellicar.in"
        },
        {
            "title": "Debug",
            "description": "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old",
            "image": "debug.svg",
            "url": "https://debug.intellicar.in/"
        }
    ],
    "mobile": [
        {
            "title": "Track (Android)",
            "description": "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old",
            "image": "track_android.svg",
            "url": "https://play.google.com/store/apps/details?id=in.intellicar.track"
        },
        {
            "title": "Track (IOS)",
            "description": "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old",
            "image": "track_ios.svg",
            "url": "https://apps.apple.com/us/app/intellicar-track/id1497490023"
        },
        {
            "title": "Intelliblu (Android)",
            "description": "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old",
            "image": "intelliblu_android.svg",
            "url": "https://play.google.com/store/apps/details?id=com.intellicar.intelliblulocksample"
        }
    ]
}